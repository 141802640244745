$body-bg: rgb(256, 256, 256);
$primary: #b6d8fe;
$secondary: #e97724;
$danger: #d41d1d;
$gunmetal: #5f6060;
$greyish-brown: #5d5b5b;

$theme-colors: (
	'gunmetal': $gunmetal,
	'greyish-brown': $greyish-brown,
);

@import '../node_modules/bootstrap/scss/bootstrap';
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*,
*::after,
*::before {
	margin: 0px;
	padding: 0px;
	text-decoration: none;
	list-style: none;
	box-sizing: inherit;
}

html {
	font-size: 100%;
}

body {
	font-family: 'Roboto', sans-serif;
	box-sizing: border-box;
}

/* CSSTransition classes  */
.menu-primary-enter {
	position: absolute;
	transform: translateX(-110%);
}

.menu-primary-enter-active {
	transform: translateX(0%);
	transition: all var(--speed) ease;
}

.menu-primary-exit {
	position: absolute;
}

.menu-primary-exit-active {
	transform: translateX(-110%);
	transition: all var(--speed) ease;
}

// Checkbox
.form-check {
	display: block;
	margin-bottom: 15px;
	padding-left: 0;
}
.form-check input {
	padding: 0;
	height: initial;
	width: initial;
	margin-bottom: 0;
	display: none;
	cursor: pointer;
}
.form-check label {
	position: relative;
	cursor: pointer;
	color: #002b71;
	font-weight: 300;
}

.form-check label:before {
	content: '';
	-webkit-appearance: none;
	background-color: transparent;
	padding: 10px;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	margin-right: 5px;
	border: 1px solid #006191;
}

.form-check input:checked + label:after {
	content: '';
	display: block;
	position: absolute;
	top: 4px;
	left: 8px;
	width: 6px;
	height: 14px;
	border: solid #006191;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

// Floating input
/****  floating-Lable style start ****/
.floating-label {
	position: relative;
	width: 100%;
	label {
		color: #254d8d;
		font-size: 14px;
		font-weight: normal;
		position: absolute;
		pointer-events: none;
		left: 5px;
		top: 5px;
		transition: 0.2s ease all;
		-moz-transition: 0.2s ease all;
		-webkit-transition: 0.2s ease all;
	}
}
.floating-input,
.floating-select {
	border-top-style: hidden;
	border-right-style: hidden;
	border-left-style: hidden;
	border-bottom-style: groove;
	background-color: transparent !important;
	font-size: 1rem;
	outline: none !important;
	font-size: 14px;
	padding: 4px 4px;
	display: block;
	width: 100%;
	height: 40px;
	border-bottom: 1px solid #002b71;
}

.floating-input:focus,
.floating-select:focus {
	outline: none;
	border-bottom: 2px solid #d26516;
}

.floating-input:focus ~ label,
.floating-input:not(:placeholder-shown) ~ label {
	top: -18px;
	font-size: 14px;
	color: #002b71;
}

.floating-select:focus ~ label,
.floating-select:not([value='']):valid ~ label {
	top: -18px;
	font-size: 14px;
	color: #002b71;
}

/* active state */
.floating-input:focus ~ .bar:before,
.floating-input:focus ~ .bar:after,
.floating-select:focus ~ .bar:before,
.floating-select:focus ~ .bar:after {
	width: 50%;
}
.floating-textarea {
	min-height: 30px;
	max-height: 260px;
	overflow: hidden;
	overflow-x: hidden;
}

/* highlighter */
.highlight {
	position: absolute;
	height: 50%;
	width: 100%;
	top: 15%;
	left: 0;
	pointer-events: none;
	opacity: 0.5;
}

/* active state */
.floating-input:focus ~ .highlight,
.floating-select:focus ~ .highlight {
	-webkit-animation: inputHighlighter 0.3s ease;
	-moz-animation: inputHighlighter 0.3s ease;
	animation: inputHighlighter 0.3s ease;
}
/* for autofill */
.floating-input:-webkit-autofill ~ label {
	top: -20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-background-clip: text;
	background-clip: text;
}

/* animation */
@-webkit-keyframes inputHighlighter {
	from {
		background: #5264ae;
	}
	to {
		width: 0;
		background: transparent;
	}
}
@-moz-keyframes inputHighlighter {
	from {
		background: #5264ae;
	}
	to {
		width: 0;
		background: transparent;
	}
}
@keyframes inputHighlighter {
	from {
		background: #5264ae;
	}
	to {
		width: 0;
		background: transparent;
	}
}

/****  floating-Lable style end ****/

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes right {
	0% {
		transform: translate(0px, 0px);
	}
	100% {
		transform: translate(80px, 0);
	}
}
@keyframes left {
	0% {
		transform: translate(0px, 0px);
	}
	100% {
		transform: translate(-80px, 0);
	}
}

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
	background-color: #d26516;
	left: 8px;
	animation: lds-ellipsis1 1500ms infinite;
}
.lds-ellipsis div:nth-child(2) {
	background-color: #fff;
	left: 8px;
	animation: lds-ellipsis2 1500ms infinite;
}
.lds-ellipsis div:nth-child(3) {
	background-color: #4ba1cb;
	left: 32px;
	animation: lds-ellipsis2 1500ms infinite;
}
@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}

/* for autofill */
.floating-input:-webkit-autofill ~ label {
	top: -20px;
}
